.error-notification {
  color: black;
  font-size: 20px;
  background-color: #F7E95A;
  padding: 19px 20px 20px;
  width: fit-content;
  max-width: 980px;
}

 .error-notification__title {
   font-size: 26px;
 }

.error-notification strong {
  text-decoration: underline;
}

.error-description {
  line-height: 24px;
}

.error-description p {
  margin: 0;
}

@media all and (max-width: 479px) {
  .notification {
    font-size: 13px;
    padding: 10px 15px;
    margin-bottom: 20px;
  }
}
