/*.header .circle {*/
/*    position: absolute;*/
/*}*/
.circle {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.circle__inner {
  position: absolute;
  width: 1440px;
  left: 50%;
  top: -670px;
  margin-left: -295px;
  -webkit-transition: fill 0.3s linear;
  transition: fill 0.3s linear;
  fill: #7f332a;
}
.circle svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
}

@media all and (max-width: 979px) {
  .circle__inner {
    width: 720px;
    height: 720px;
    margin-left: 0;
    margin-right: -294px;
    left: auto;
    right: 0;
    top: -332px;
  }
}
@media all and (max-width: 579px) {
  .circle__inner {
    width: 414px;
    height: 414px;
    left: auto;
    right: 0;
    top: -177px;
    margin-left: 0;
    margin-right: -150px;
  }

  .popup-product-quantity .circle {
    left: 0;
    top: 0;
    overflow: visible;
  }
  .popup-product-quantity .circle__inner {
    left: 50%;
    right: auto;
    top: 50%;
    margin-right: 0;
    margin-left: -207px;
    margin-top: -207px;
  }
}


