.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  background-color: #e2e569;
}


/*.header__inner {*/
/*  position: relative;*/
/*  z-index: 3;*/
/*  max-width: 980px;*/
/*  margin: 0 auto;*/
/*  padding: 96px 0 0;*/
/*  height: 372px;*/
/*}*/
/*.header__outer {*/
/*  position: relative;*/
/*  height: 372px;*/
/*  min-height: 70px;*/
/*  overflow: hidden;*/
/*}*/


/*header-bar*/
/*.header-bar {*/
/*  position: absolute;*/
/*  display: -webkit-box;*/
/*  display: flex;*/
/*  right: 15px;*/
/*  top: 26px;*/
/*  line-height: 45px;*/
/*  color: #231f20;*/
/*}*/
/*.header-bar a {*/
/*  color: #231f20;*/
/*}*/
.header-bar__list-item {
  margin-left: 40px;
}
.header-bar__list-item[data-type="address"] {
  font-size: 17px;
  line-height: 1;
  font-weight: bold;
  padding-top: 14px;
  cursor: pointer;
  width: 100%;
  min-width: 280px;
  display: flex;
  justify-content: flex-end;
}
.header-bar__list-item[data-type="address"] .header-bar__item {
  overflow: hidden;
  height: 1em;
  white-space: nowrap;
  display: flex;
}
.header-bar__list-item[data-type="address"] .street{
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-bar__list-item[data-type="address"] .house{
  flex-shrink: 0;
}

.header-bar__list-item[data-type="price"] {
  font: 900 45px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.header-bar__list-item[data-type="cart"] {
  font-size: 28px;
  padding: 0 10px;
  font-weight: bold;
  border: 3px solid #231f20;
  border-radius: 8px;
}
.header-bar__type {
  position: absolute;
  color: rgba(255, 239, 230, 0.4);
  font-family: 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 13px;
  font-weight: 400;
}


/*corp-navigation*/
.corp-nav {
  position: absolute;
  left: 15px;
  top: 41px;
}
.corp-nav__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  color: #ca987d;
  white-space: nowrap;
}
.corp-nav__list-item {
  margin-bottom: 9px;
}
.corp-nav a {
  color: #ca987d;
}
.corp-nav a:hover {
  color: #ca987d;
}
.corp-nav__note {
  font-family: 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 11px;
  font-weight: normal;
  padding-left: 9px;
}
.corp-nav__count {
  background-color: #637c6c;
  border-radius: 9px;
  display: inline-block;
  min-width: 18px;
  line-height: 18px;
  text-align: center;
  padding: 2px 4px 0;
  color: #b24636;
  margin-left: 3px;
  font: 900 11px/16px 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  vertical-align: top;
}


/*logo*/
.logo {
  position: relative;
  margin: 0 auto 16px;
  width: 637px;
  text-align: center;
}
.brand-name__logo {
  background: url("../../../../assets/images/gdz-full-logo.svg") no-repeat 50%/contain;
  color: transparent;
}
.brand-name__wtf {
  position: absolute;
  color: #ca987d;
  width: 400px;
  text-align: center;
  margin-left: -200px;
  left: 50%;
  margin-top: 22px;
}
.brand-name__wtf svg {
  fill: currentColor;
}


/*header benefits*/
.header-benefits {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 49px;
  color: #ca987d;
}
.header-benefits__list-item {
  position: relative;
  width: 240px;
  text-align: center;
}
.header-benefits a {
  color: currentColor;
}
.header-benefits__highlight {
  display: block;
  font-size: 35px;
  line-height: 1;
}
.header-benefits__note {
  position: absolute;
  text-align: center;
    margin-left: -130px;
  left: 50%;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
}

/*Navigation*/
.navigation {
  margin: 0;
  font-size: 25px;
  line-height: 1;
  font-weight: bold;
  text-transform: lowercase;
}
.navigation__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
}
.navigation__list-item {
  margin: 0 9px;
}

.navigation__list-item-with-icon svg {
  height: 16px;
  width: 16px;
}

.navigation__item {
  color: #231f20;
}
a.navigation__item:hover {
  color: #fff;
  text-decoration: none;
}
.navigation>svg {
  fill: currentColor;
  vertical-align: top;
  height: 18px;
}

/*stiky header*/
.header-sticky-nav__close {
  display: none;
}

.header-sticky-nav {
  position: relative;
  z-index: 5;
  min-height: 76px;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.header-sticky-nav__mobile {
  position: absolute;
  z-index: 14;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  top: 22px;
  left: 20px;
}
.header-sticky-nav .header-bar {
  top: 16px;
}
.header-sticky-nav .header-bar__list-item[data-type="price"] {
  font-size: 28px;
}
.header-sticky-nav .header-bar__list-item[data-type="cart"] {
  font-size: 25px;
  padding-top: 0;
}
.header-sticky-nav .header-bar__list-item[data-type="address"] {
  position: absolute;
  font-size: 13px;
  line-height: 1;
  right: 0;
  top: 34px;
  padding-top: 0;
  display: flex;
  justify-content: flex-end;
  min-width: 280px;
}
.header-sticky-nav .header-bar__type {
  font-size: 11px;
  margin-top: -4px;
  margin-left: 4px;
}
.header-sticky-nav .header-benefits {
  position: absolute;
  z-index: 4;
  left: 0;
  top: 16px;
}
.header-sticky-nav .header-benefits__highlight {
  font-size: 30px;
}
.header-sticky-nav .header-benefits__note {
  font-size: 13px;
}
.header-sticky-nav .navigation {
  -webkit-transform: translate(0, 22px);
  transform: translate(0, 22px);
  font-size: 20px;
}
.header-sticky-logo {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 50%;
  margin-left: -102px;
  -webkit-transform: translate(0, -41px);
  transform: translate(0, -41px);
  opacity: 0.2;
  -webkit-transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.header-sticky-logo .brand-name__logo {
  width: 204px;
  height: 41px;
}
.body-header-minimal .header-sticky-logo {
  -webkit-transform: translate(0, 53px);
  transform: translate(0, 53px);
  opacity: 1;
}

/*circle*/
.header .circle {
  position: absolute;
}

.brand-huge-sale {
  position: absolute;
  width: 50%;
  left: 25%;
  bottom: -10px;
}
