/* TODO must be deleted from Pages folder */

.page-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-wrapper {
  background-color: #fff;
  padding-top: 32px;
  position: relative;
  z-index: 2;
}
.page-wrapper__outer {
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  transition: opacity 0.5s, visibility 0.5s;
}

.page-container {
  min-height: 100%;
  padding-top: 426px;
}

@media all and (max-width: 979px) {
  .page-container {
    padding-top: 436px;
  }
}

@media all and (max-width: 580px) {
  .page-container {
    padding-top: 285px;
  }
}

@media (max-width: 479px) {
  .content-page {
    padding: 0 15px;
  }
}
