.product-code__hash {
  vertical-align: bottom;
  font-size: 15px;
}

.product-code__code {
  font-size: 0.8em;
  line-height: 1em;
}

.popup-product__title {
  .product-code__hash {
    vertical-align: baseline;
  }
}
