.cart-confirm-popup-layout {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    position: absolute;
}

.cart-confirm-popup__title {
    text-align: center;
}

.cart-confirm-popup__description {
    color: #ffefe6;
    max-width: 400px;
    margin: 60px auto 0;
}

.cart-confirm-popup {
    max-width: 600px;
    padding: 0 15px;
}

.cart-confirm-popup__buttons-section {
    display: flex;
    justify-content: space-around;
}

.cart-confirm-popup__buttons-section {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}

.cart-confirm-popup__confirm-buttom {
    margin-top: 10px;
    display: inline-block;
    color: #ffefe6;
    background-color: #607969;
    text-align: center;
    padding: 5px 15px;
    border: none;
    border-radius: 22px;
    cursor: pointer;
    width: 250px;
}

.cart-confirm-popup__decline-button {
    margin-top: 10px;
    color: #ca987d;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
}

.cart-confirm-popup__decline-button:hover {
    text-decoration: underline;
    cursor: pointer;
}
