.suggested-product_img {
  display: flex;
  justify-content: center;

}
@media all and (max-width: 399px) {
  .suggested-product-list__item {
    grid-template-rows: 35% 16% 10% 10%!important;
  }
}
@media (min-width: 400px) and (max-width: 599px) {
  .suggested-product_img {
    grid-template-rows: 35% 16% 10% 10%!important;
  }
}
@media (min-width: 600px) and (max-width: 2400px) {
  .suggested-product-list__item {
    grid-template-rows: 35% 13% 6% 7%!important;
  }
}

.suggested-product_img-drink img {

}

.suggested-product-list__item {
  color: #ca987d;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 43% 16% 10% 10%;
  height: 100%;
}
.suggested-product-list__item .secondary-goods__title, .suggested-product-list__item .secondary-goods__title-box {
 text-align: center;
}

