.footer {
  font-size: 13px;
  padding: 0 20px 30px;
}
.footer__cpr {
  font-size: 17px;
  margin-bottom: 20px;
}
.footer-nav {
  margin-bottom: 26px;
}
.footer__rules {
  margin-bottom: 19px;
}
.footer__rules p {
  margin-bottom: 10px;
}
.footer__instalments {
  font-size: 13px;
  margin-bottom: 45px;
}
.footer__instalments-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__contacts {
  font-size: 13px;
}
.footer__contacts p {
  margin-bottom: 19px;
}

.footer__contacts p a {
  white-space: nowrap;
}

.footer {
  max-width: 980px;
  margin: 0 auto;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  padding: 0 25px 80px;
}
.footer__rules {
  margin-bottom: 39px;
}
.footer__rules p {
  margin-top: 0;
  margin-bottom: 26px;
}
.footer-nav {
  margin-bottom: 56px;
}
.footer-nav__list {
  list-style-type: none;
  padding: 0;
  margin: 0 -9px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.footer-nav__list-item {
  margin: 0 9px;
}
.footer__payments {
  margin-bottom: 21px;
  display: flex;
  justify-content: center;
}
.footer__instalments {
  color: rgba(35, 31, 32, 0.5);
  font-size: 15px;
  margin-bottom: 75px;
}
.footer__instalments h3 {
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 6px;
}
.footer__instalments img {
  vertical-align: top;
  max-width: 100%;
  width: auto;
  margin: 0 3px;
}
.footer__cpr {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 30px;
}
.footer__contacts {
  font-size: 15px;
}
.footer__contacts p {
  margin-top: 0;
  margin-bottom: 26px;
}
.footer-developers {
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  text-transform: uppercase;
}
.footer-developers a {
  display: inline-block;
  fill: #231f20;
  text-align: left;
}
.footer-developers .st0 {
  fill: #ca987d;
}

.footer .footer__payments img,
.footer .footer__instalments img {
  height: 50px;
}

.delete-button {
  margin-top: 10px;
  cursor: pointer;
}

.delete-button:hover {
  text-decoration: underline;
}

.privat-policy {
  margin-top: 5px;
}

@media all and (max-width: 579px) {
  .footer {
    font-size: 13px;
    padding: 0 20px 30px;
  }

  .footer__cpr {
    font-size: 17px;
    margin-bottom: 20px;
  }

  .footer-nav {
    margin-bottom: 26px;
  }

  .footer__rules {
    margin-bottom: 19px;
  }

  .footer__rules p {
    margin-bottom: 10px;
  }

  .footer__instalments {
    font-size: 13px;
    margin-bottom: 45px;
  }

  .footer__contacts {
    font-size: 13px;
  }

  .footer__contacts p {
    margin-bottom: 19px;
  }

  .footer .footer__payments img,
  .footer .footer__instalments img
  {
    height: 30px;
  }
}


