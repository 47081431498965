.popup-form {
  margin: 0 auto;
  max-width: 520px;
  text-align: center;
}
.popup-form .form-label {
  color: #fff;
}
.popup-form .form-label--alt {
  color: #ca987d;
}
.popup-form .form-with-sidelabel {
  margin: 0 auto;
}
.popup-form .form-select,
.popup-form .form-input {
  margin: 0 auto;
  border-width: 0;
}
.popup-form .form-group {
  margin-bottom: 26px;
}
.popup-form .form-input-big {
  padding: 11px 10px 11px 23px;
}
.popup-form .form-footer-action {
  font-size: 17px;
  margin-bottom: 20px;
}
.popup-form .form-footer-action a {
  color: #ca987d;
}
.popup-form .popup-form-button {
  margin-top: 30px;
  margin-bottom: 30px;
}
