.cart-table {
  margin-bottom: 0;
}
.cart-table__picture img {
  width: auto;
  height: auto;
  max-width: 75px;
}
.cart-table__list {
  display: table;
  padding: 0;
  margin: 0px 0 15px 0;
  border-collapse: collapse;
  list-style-type: none;
  border-bottom: 2px solid #fef0e5;
}
.cart-table__list-item {
  display: table-row;
  border-top: 2px solid #ffefe5;
}
.cart-table__picture {
  display: table-cell;
  padding: 9px 23px 9px 0;
  width: 158px;
  min-height: 92px;
  vertical-align: middle;
  text-align: center;
}
.cart-table__picture img {
  vertical-align: top;
}
.cart-table__picture[image-type="alt"] {
  padding-bottom: 0;
  vertical-align: bottom;
}
.cart-table__content {
  width: 100%;
  display: table-cell;
  padding: 9px 23px 9px 0;
  min-height: 92px;
  vertical-align: middle;
  font: 15px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.cart-table__content p {
  margin-top: 5px;
  margin-bottom: 0;
}
.cart-table__title {
  margin-top: 0;
  margin-bottom: 0;
  font: bold 25px/1 'Circe-Bold', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.cart-table__title a {
  color: currentColor;
}
.cart-table-quantity {
  white-space: nowrap;
  width: 100%;
  display: table-cell;
  padding: 9px 23px 9px 0;
  min-height: 92px;
  vertical-align: middle;
}
.cart-table-quantity__inner {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}
.cart-table-quantity__controls {
  background-color: #41463e;
  color: #ca987d;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  padding: 0;
  margin: 0;
  text-align: center;
  vertical-align: top;
}
.cart-table-quantity__controls[data-button-type="plus"] {
  padding-top: 5px;
}
.cart-table-quantity__controls:hover {
  color: #ffefe6;
  background-color: #637c6c;
}
.cart-table-quantity__numbers {
  font: 25px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #231f20;
  min-width: 60px;
  padding: 0 10px;
  text-align: center;
}
.cart-table-price {
  display: table-cell;
  padding: 9px 0 9px 0;
  min-height: 92px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}

.cart-table-delete-button {
  border: none;
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
  color: #ca987d;
}
.cart-table-delete-button__timer {
  position: relative;
  font: 0/0 a;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}
.cart-table-delete-button__timer:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73.2 73.2'%3E%3Cpath fill='none' stroke='%23ca987a' stroke-miterlimit='10' stroke-width='5' d='M1.77 1.77l69.66 69.66M71.43 1.77L1.77 71.43'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}

.cart-table-price__inner {
  min-width: 110px;
  font: 900 35px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.cart-table-price__realization {
  text-decoration: line-through;
}
.cart-table-price__realization_inner {
  min-width: 110px;
  font: 900 35px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: 12px;
}
.cart-table-price__box {
  display: flex;
  flex-direction: column;
}
.cart-table-total {
  border-top: 2px solid #ffefe5;
  padding: 19px 0 35px 0;
  display: flex;
  justify-content: flex-end;
}

.cart-table-total__content {

}

.cart-table-total__content-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-table-total__content-box + .cart-table-total__content-box {
  margin-top: 10px;
}

.cart-table-total__label {
  font-size: 25px;
  font-weight: bold;
}
.cart-table-total__price {
  font: 900 45px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding-left: 25px;
}

@media all and (max-width: 822px) {
  .cart-table-quantity {
    padding-right: 20px;
  }

  .cart-table-quantity__controls {
    width: 32px;
    height: 32px;
    font-size: 21px;
    line-height: 1;
  }

  .cart-table-quantity__controls[data-button-type="plus"] {
    padding-top: 4px;
  }

  .cart-table-quantity__numbers {
    font-size: 20px;
    line-height: 1;
    color: #231f20;
    min-width: 44px;
    text-align: center;
  }

  .cart-table__title {
    font-size: 17px;
  }

  .cart-table__picture img {
    height: auto;
    width: 100%;
    max-width: 75px;
  }

  .cart-table-price__inner {
    min-width: 1px;
    font-size: 25px;
  }

  .cart-table-total__label {
    font-size: 15px;
  }

  .cart-table-total__price {
    font-size: 35px;
    padding-left: 12px;
  }
}

@media all and (max-width: 579px) {
  .cart-table {
    margin-bottom: 15px;
  }

  .cart-table__list {
    display: block;
  }

  .cart-table__list-item {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-top: 2px solid #ffefe5;
    padding: 10px 0;
  }

  .cart-table__picture {
    -webkit-box-ordinal-group: 2;
    order: 1;
    border: none;
    padding: 0;
    margin: 0 0 5px;
    min-height: 0;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: 100px;
    text-align: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 65px;
  }

  .cart-table__picture img {
    width: auto;
    height: auto;
    max-width: 100px;
  }

  .cart-table__picture[image-type="alt"] img {
    max-width: 65px;
  }

  .cart-table__content {
    -webkit-box-ordinal-group: 1;
    order: 0;
    width: calc(100% - 114px);
    padding: 0;
    margin: 0 0 5px;
    border: none;
    min-height: 0;
    font-size: 13px;
  }

  .cart-table-quantity {
    -webkit-box-ordinal-group: 3;
    order: 2;
    border: none;
    padding: 0;
    margin: 0;
    min-height: 0;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    align-self: center;
  }

  .cart-table-price {
    -webkit-box-ordinal-group: 4;
    order: 3;
    border: none;
    padding: 0;
    margin: 0;
    min-height: 0;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    align-self: center;
  }
}

@media all and (max-width: 479px) {
  .cart-table-total {
    padding-right: 15px;
    padding-top: 14px;
  }

  .cart-table__list {
    display: block;
  }

  .cart-table__list-item {
    padding: 10px 15px;
  }
}
