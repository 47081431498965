.c-brand {
  color: #ca987d;
}

.non-conteact-delivery {
  max-width: 350px;
}
.non-conteact-delivery .non-contact-delivery-description {
  color: #ca987d;
  font-size: 13px;
  margin-top: 5px;
  padding-left: 15px;
}

.form-payments-and-total {
  display: -webkit-box;
  display: flex;
  padding-bottom: 17px;
  border-bottom: 2px solid #eddcd3;
  margin-bottom: 10px;
}
.form-payments-and-total__payment-type {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.form-payments-and-total-general {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  text-align: right;
}
.form-payments-and-total__price {
  display: flex;
}
.form-payments-and-total-general__label {
  display: inline-block;
  vertical-align: middle;
  font-size: 25px;
  padding-right: 14px;
}
.form-payments-and-total-general__total {
  display: inline-block;
  font: 900 45px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  vertical-align: middle;
}
.form-payments-and-total-general__note {
  display: inline-block;
  font-size: 15px;
}
.form-finish-order {
  display: grid !important;
  grid-template-columns: 35% 40% 25%;
  border-bottom: 2px solid #eddcd3;
  padding-bottom: 17px;
  margin-bottom: 35px;
  min-height: 85px;
  padding-top: 10px;
}

.form-finish-order .error-notification {
  margin: 0 0 15px 0;
}

.form-finish-order__backto {
  max-width: 150px;
}
.form-finish-order__backto a {
  color: #637c6c;
}

.form-group {
  margin-bottom: 15px;
}
.form-label {
  font-size: 17px;
  line-height: 1;
  font-weight: bold;
  color: #ca987d;
  margin-bottom: 3px;
}
.form-label__note {
  font-size: 15px;
  color: #ca987d;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}
.form-label--flex {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
}
.form-label__value {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.form-input {
  font: 25px/1.2 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border: 2px solid #eddcd3;
  padding: 5px 10px 5px;
  margin: 0;
  vertical-align: top;
  background: #fff;
  border-radius: 6px;
}
.form-preorder-input {
  width: 225px;
  font: 18px/1.2 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.form-input:focus,
.form-input:hover {
  border-color: #48d57b;
  outline: none;
}
.form-input-big {
  font-size: 40px;
  line-height: 46px;
  padding: 7px 15px;
}
.form-select {
  font: 40px/46px 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-width: 0;
  margin: 0;
  vertical-align: top;
  background: #fff;
  border-radius: 6px;
}
.form-select-input {
  display: block;
  margin: 0;
  padding: 9px 15px;
  background: none;
  width: 100%;
  font: 40px/46px 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.form-size-normal {
  width: 387px;
}
.form-size-long {
  width: 339px;
}
.form-size-medium {
  width: 250px;
}
.form-size-full {
  width: 100%;
}
.form-size-house {
  width: 160px;
}
.form-required .form-label {
  color: #231f20;
}
.form-required .form-input {
  border-color: #637c6c;
}
.form-note {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 17px;
  padding: 0 15px;
}
.form-grid {
  display: -webkit-box;
  display: flex;
}
.form-grid__cell {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
}
.form-grid__cell--phone-prefix {
  width: 134px;
}
.form-grid__cell--full {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.form-grid__cell--equal {
  -webkit-box-flex: 1.5;
  flex: 1.5;
}
.form-grid__cell--house {
  width: 129px;
}
.form-grid--sps-normal {
  margin-left: -9px;
}
.form-grid--sps-normal .form-grid__cell {
  margin-left: 9px;
}
.form-settime-action {
  display: inline-block;
  margin-right: 5px;
}
.cart_form-preorder-button {
  color: #ca987d;
  border: 1px solid #eddcd3;
  border-radius: 40px;
  padding: 7px 25px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 65px;
  margin-right: 5px;
  line-height: 0.9;
  margin-top: 5px;
  justify-content: center;
  cursor: pointer;
}
.cart_form-preorder-button.current {
  font-size: 32px;
}
.cart_form-preorder-button.active {
  color: #ffefe6;
  background-color: #41463e;
  border-width: 0;
  border-radius: 40px;
}
.form-settime-action .preorder-description {
  color: #ca987d;
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
}
.preorder-description {
  max-width: 400px;
  text-align: center;
}

.form-settime-action__pic {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 13px;
}
.form-settime-action__note {
  font-size: 17px;
}
.form-list-radio {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}
.form-list-radio__list-item {
  margin-bottom: 6px;
  margin-right: 6px;
}

.form-list-radio__item {
  cursor: pointer;
}
.form-list-radio__value {
  display: inline-block;
  white-space: nowrap;
  color: #ca987d;
  border: 1px solid #eddcd3;
  border-radius: 40px;
  padding: 7px 19px;
}
.form-list-radio__value[disabled] {
  color: #eddcd3;
  cursor: not-allowed;
}
.form-list-radio__value:hover {
  color: #fff;
  background-color: #607969;
  border-width: 0;
  padding: 8px 20px;
}
.form-list-radio__radio {
  position: absolute;
  left: -9999px;
}
.form-list-radio__radio:checked + .form-list-radio__value {
  color: #ffefe6;
  background-color: #41463e;
  border-width: 0;
  padding: 8px 20px;
}
.form-list-radio__radio:checked + .form-list-radio__value[disabled] {
  color: white;
  background-color: #ffefe6;
  border-color: #ffefe6;
  cursor: not-allowed;
}
.form-preorder {
  position: relative;
  background-color: #ffefe6;
  border-radius: 5px;
  max-width: 491px;
  padding: 19px 135px 13px 30px;
  font-size: 17px;
  line-height: 1.2;
  margin-bottom: 26px;
}
.form-preorder p {
  margin-top: 0;
  margin-bottom: 17px;
}
.form-preorder--active {
  display: block;
}
.form-preorder__cancel {
  position: absolute;
  top: 19px;
  right: 30px;
  font-size: 17px;
  line-height: 1.2;
  padding: 0;
  margin: 0;
  color: #b24636;
  background: none;
  border: none;
}
.form-preorder__cancel:hover {
  text-decoration: underline;
}
.form-preorder .form-group p {
  margin-bottom: 10px;
}

.form-with-sidelabel {
  position: relative;
}
.form-with-sidelabel-label {
  position: absolute;
  left: 100%;
  margin-left: 17px;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  text-align: left;
}
.form-with-sidelabel-label__inner {
  font-size: 15px;
  color: #ca987d;
}

.form-payments-and-total__cash-payment-renting {
  display: flex;
  justify-content: flex-end;
}

.form-payments-and-total__cash-payment-renting .label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 150px;
  font-size: 17px;
  line-height: 1;
  font-weight: bold;
  color: #ca987d;
}
.form-payments-and-total__cash-payment-renting .input {
  width: 110px;
  padding: 15px 0 15px 15px;
}

@media all and (max-width: 979px) {
  .form-grid--address {
    flex-wrap: wrap;
  }

  .form-grid--address .form-grid__cell {
    margin-bottom: 10px;
  }

  .form-grid--address .form-grid__cell--equal {
    min-width: 110px;
    max-width: 129px;
  }
}

@media all and (max-width: 822px) {
  .form-payments-and-total {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 0 0 15px;
    margin-bottom: 15px;
  }

  .form-payments-and-total-general {
    padding-right: 0;
  }

  .form-finish-order {
    display: -webkit-box;
    display: flex;
    grid-template-columns: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: center;
    padding: 0 0 15px;
  }

  .form-finish-order__backto {
    -webkit-box-ordinal-group: 2;
    order: 1;
    max-width: none;
    display: none;
    font-size: 17px;
  }

  .form-finish-button__action-wrapper {
    display: grid;
    grid-template-columns: 20% 60% 20%;
  }
  .form-finish-button__action-wrapper .error-notification {
    grid-row: 1;
    grid-column: 2;
  }
  .form-privacy-policy-wrapper {
    grid-row: 2;
    grid-column: 2;
  }
  .form-finish-button__action-wrapper .btn-primary {
    grid-row: 4;
    grid-column: 2;
  }
  .form-finish-button__action-wrapper .form-finish-note-container {
    grid-row: 5;
    grid-column: 2;
    text-align: start;
  }
  .form-privacy-policy-container input {
    margin-bottom: 15px;
  }
}

.cart-shipping {
  margin-bottom: 40px;
}

@media all and (max-width: 579px) {
  .form-finish-button__action-wrapper {
    display: grid;
    grid-template-columns: 10% 1fr 10%;
  }
  .form-finish-button__action-wrapper .error-notification {
    grid-column: 1/6;
    width: 100%;
  }
  .form-privacy-policy-wrapper {
    grid-row: 2;
    grid-column: 2;
  }
  .form-finish-button__action-wrapper .btn {
    grid-row: 3;
    grid-column: 2;
  }

  .form-privacy-policy-container input {
    margin-bottom: 30px;
  }

  .form-finish-button__action-wrapper .form-finish-note-container {
    grid-row: 4;
    grid-column: 2;
    text-align: start;
  }

  form-finish-note-container

  .cart_form-preorder-button,
  .form-settime-action {
    width: 100%;
  }

  .cart-shipping .form-size-normal {
    width: auto;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
  }

  .cart-shipping .form-input.form-size-normal {
    width: 100%;
  }

  .form-grid__cell--phone-prefix {
    width: 95px;
  }

  .form-grid__cell--house {
    width: calc(33.33% - 9px);
    -webkit-box-flex: 0;
    flex: 0 0 auto;
  }

  .form-grid--address .form-size-normal {
    width: calc(100% - 9px);
  }

  .form-grid--address .form-grid__cell--equal {
    width: calc(33.33% - 9px);
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    max-width: none;
    min-width: 0;
  }

  .form-input-big {
    padding: 5px 10px;
    font: 25px/1.2 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  .form-label--flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .form-label--flex .form-label__note {
    margin-top: 2px;
  }

  .form-preorder {
    padding: 10px 15px;
  }

  .form-preorder .form-size-medium {
    width: 100%;
  }

  .form-preorder__cancel {
    position: static;
    text-align: right;
    margin-bottom: 10px;
  }

  .form-payments-and-total {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 0 0 15px;
    margin-bottom: 15px;
  }

  .form-payments-and-total-general {
    padding-right: 0;
  }

  .form-payments-and-total-general__total {
    font-size: 10px;
  }

  .form-payments-and-total-general__label {
    font-size: 15px;
    padding-right: 7px;
  }

  .form-payments-and-total-general__note {
    font-size: 15px;
  }

  .form-finish-order {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: center;
    padding: 0 0 15px;
  }

  .form-finish-order__backto {
    position: static;
    -webkit-box-ordinal-group: 2;
    order: 1;
    max-width: none;
    font-size: 17px;
  }

  .form-with-sidelabel-label {
    position: relative;
    left: auto;
    margin: 7px 0 0 0;
  }
}
@media all and (max-width: 479px) {
  .form-note {
    padding: 0;
  }
  .cart-shipping {
    padding-left: 15px;
    padding-right: 15px;
  }

  .form-finish-order {
    grid-template-columns: 100%;
  }

  .form-finish-order,
  .form-payments-and-total {
    padding-left: 15px;
    padding-right: 15px;
  }

  .form-list-radio__list-item {
    font-size: 16px;
  }

  .form-payments-and-total__price {
    flex-direction: column;
  }
}

.form-finish-button {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
