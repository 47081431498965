@media all and (min-width: 680px) {
  .show-on-mobile {
    display: none !important;
  }
}

@media all and (min-width: 480px) {
  .show-on-4cards {
    display: none !important;
  }
}

@media print {
  @page { margin: 1cm; }

  header,
  .footer-test-section {
    display: none;
    height: 0;
  }

  body .page-container {
    padding: 0;
    margin: 0;
  }
}

