
.popup {
  min-height: 100vh;
  position: relative;
  background-color: #b24636;
  -webkit-transition: opacity 0.3s, visibility 0.3s;
  transition: opacity 0.3s, visibility 0.3s;
}

.popup--show {
  opacity: 1;
  visibility: visible;
}
.popup .circle {
  position: absolute;
}
.popup-outer {
  position: relative;
  z-index: 12;
  padding: 20px 0 40px;
  margin: 0 auto;
  min-height: 650px;
  height: 100%;
}
.popup-footer-note {
  width: 540px;
  color: #ca987d;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  margin: 50px auto 0;
}
.popup-footer-note a {
  color: currentColor;
}
.popup-note {
  color: #ca987d;
  font-size: 15px;
  margin-bottom: 17px;
  margin-top: 0;
  text-align: center;
}
.popup-nav {
  list-style-type: none;
  padding: 0;
  margin: 0 0 41px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.popup-nav__list-item {
  margin: 0 3px;
}
.popup-nav__item {
  display: block;
  border: 2px solid #c9987d;
  border-radius: 30px;
  font-size: 25px;
  line-height: 31px;
  padding: 4px 19px 6px;
  color: #ffefe6;
  background: none;
}
.popup-nav__item:hover {
  color: #fff;
  background-color: #607969;
  border-width: 0;
  padding: 6px 21px 8px;
}
.popup-nav__item--selected {
  color: #ffefe6;
  background-color: #41463e;
  border-width: 0;
  padding: 6px 21px 8px;
}
.popup__close {
  position: absolute;
  z-index: 91;
  left: 50%;
  top: 22px;
  width: 44px;
  height: 44px;
  border: none;
  background: none;
  padding: 0;
  margin: 0 0 0 406px;
  font: 0/0 a;
  color: transparent;
}
.popup__close:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 44px;
  height: 44px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73.2 73.2'%3E%3Cpath fill='none' stroke='%23fff0e7' stroke-miterlimit='10' stroke-width='5' d='M1.77 1.77l69.66 69.66M71.43 1.77L1.77 71.43'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}

.close {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 10px;
}

.btn-close-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 10px;
}

.close:before, .close:after {
  position: absolute;
  content: ' ';
  height: 60px;
  width: 3px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}



@media all and (max-width: 822px) {
  .popup-outer {
    padding-top: 63px;
    width: 95%;
    overflow-x: hidden;
  }

  .close:before, .close:after {
    height: 30px;
  }
  .close {
    margin: 0;
  }
}

@media all and (max-width: 979px) {
  .popup__close {
    margin-left: 0;
    left: auto;
    width: 34px;
    height: 34px;
    top: 14px;
    right: 47px;
  }
  .popup__close:before {
    width: 100%;
    height: 100%;
  }
}
@media all and (max-width: 579px) {
  .popup__close {
    width: 26px;
    height: 26px;
    right: 44px;
    top: 16px;
  }
  .popup-nav {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .popup-nav__list-item {
    margin-bottom: 6px;
  }
  .popup-nav__item {
    font-size: 17px;
    line-height: 23px;
  }
  .popup .popup-footer-note {
    left: 0;
    width: 100%;
    padding: 0 20px;
    font-size: 11px;
    margin: 0;
  }
  .popup--product .popup__close {
    width: 44px;
    height: 44px;
    right: 10px;
    top: 10px;
  }
  .popup-product {
    padding: 0 15px;
  }
  .popup-product-child-title {
    color: black;
  }
  .popup-product__title {
    margin-bottom: 20px;
  }
  .popup-product-quantity {
    position: relative;
    z-index: 1;
  }
  .popup-product-quantity .circle {
    left: 0;
    top: 0;
    overflow: visible;
  }
  .popup-product-quantity .circle__inner {
    left: 50%;
    right: auto;
    top: 50%;
    margin-right: 0;
    margin-left: -207px;
    margin-top: -207px;
  }
  .popup-form {
    padding: 0 15px;
  }
  .popup-form .form-size-long {
    width: 100%;
    max-width: 339px;
  }
  .popup-form .form-group {
    margin-bottom: 15px;
  }
}
.popup-product-child-title {
  color: black;
  font-size: 17px;
}
.popup-product-child-announce {
  margin-top: 5px;
  font-size: 14px;
}
.popup-product-child {
  margin-bottom: 10px;
}
.popup-product-ingredients {
  padding-top: 15px;
}

.popup-modal {

}
