button:focus {outline:0;}

.btn {
  display: inline-block;
  cursor: pointer;
  padding: 12px 36px 16px;
  border-width: 0;
  white-space: nowrap;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #607969!important;
  -webkit-transition: color 0.1s linear, background-color 0.1s linear, box-shadow 0.1s linear;
  transition: color 0.1s linear, background-color 0.1s linear, box-shadow 0.1s linear;
  min-height: 88px;
  border-radius: 44px;
  text-align: center;
  font-size: 55px;
  line-height: 60px;
  font-weight: 700;
  color: #ffefe6;
  text-decoration: none;
  min-width: 275px;
}

.btn-small {
  font-size: 35px;
}

.btn:hover {
  color: #fff;
  background-color: #6a8875;
  text-decoration: none;
}

@media all and (max-width: 579px) {
  .btn {
    padding: 10px 26px 13px;
    font-size: 35px;
    line-height: 40px;
    min-height: 63px;
    min-width: 175px;
  }

  .btn-small {
    padding: 10px 20px 13px;
    font-size: 21px;
    line-height: 27px;
    min-height: 50px;
    min-width: 175px;
  }
}

/* todo GET STYLES FOR disabled button from designer if needed */
.btn[disabled] {
  background-color: #ffefe6;
  color: white;
  cursor: not-allowed;
}
