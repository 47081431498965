

@media all and (max-width: 1299px) {
  .privat-policy-container {

  }

  .privat-policy-description {
    font-size: 15px;
  }

  .btn-privat-policy-confirm {
    margin: 10px auto;
  }
}

@media (min-width: 1300px) and (max-width: 3200px) {
  .privat-policy-container {

  }

  .btn-privat-policy-confirm {
    margin: 20px auto;

  }
}

.privat-policy-description {
  margin: 1rem 0;
}

#btnPrivacyPolicyConfirm {
  grid-area: btnPrivacyPolicyConfirm;
  font-size: 30px;
  height: 50px;
  min-height: 60px
}

#btnPrivacyPolicyCancel {
  grid-area: btnPrivacyPolicyCancel;
  font-size: 30px;
  min-height: 60px;
  margin-left: 10px;
}

.privacy-policy-actions-container {
  display: flex;
  grid-area: privacy-policy-actions;
}

#linkCookie {
  grid-area: linkCookie;
}

.btn-privat-policy-confirm {
  max-height: 55px;
}

.privat-policy-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #e9e9e9;
  z-index: 51;
  padding-bottom: 40px;
}

.privacy-policy-actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 750px) {
  .privacy-policy-actions-wrapper {
    flex-direction: column;
    align-items: start;
  }
}
