.order-feedback-form .feedback-element
{
    border: 1px solid #eddcd3;
    width: fit-content;
    padding: 7px 20px;
    border-radius: 20px;
    color: #ca987d;
    margin-bottom: 5px;
    margin-right: 5px;
}

.feedback-section {
    margin-bottom: 20px;
}

.feedback-list {
    display: flex;
}

.feedback-element.active {
    background-color: #41463e;
    color: #ffefe6;
}

.feedback-element:hover {
    background-color: #607969;
    color: white;
}

.order-feedback-form textarea {
    max-width: 100%;
    border: 0;
}

.order-feedback-form textarea:focus{
    outline: 0;
}

.feedback-submit-button {
    width: 100%;
    display: flex;
    justify-content: center;
}

.feedback-section-title {
    color: #637c6c;
    font-size: 30px;
    line-height: 1;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 19px;
    text-transform: lowercase;
}

.feedback-section-title.internal {
    color: #ca987d;
}

.feedback-comment {
    border: 1px solid #eddcd3;
    width: fit-content;
    padding: 7px 20px;
    border-radius: 20px;
    color: #ca987d;
    margin-bottom: 5px;
    max-width: 100%;
}

.feedback-conclusion-checker {
    margin-bottom: 25px;
}
