.cart-confirm-popup-layout {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    position: absolute;
}

.cart-confirm-popup__title {
    text-align: center;
}

.popup-modal__description {
    color: black;
}

.cart-confirm-popup {
    max-width: 600px;
    padding: 0 15px;
}

.cart-confirm-popup__buttons-section {
    display: flex;
    justify-content: space-around;
}

.cart-confirm-popup__buttons-section {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}

.cart-confirm-popup__confirm-buttom {
    margin-top: 10px;
    display: inline-block;
    color: #ffefe6;
    background-color: #607969;
    text-align: center;
    padding: 5px 15px;
    border: none;
    border-radius: 22px;
    cursor: pointer;
    width: 250px;
}

.cart-confirm-popup__decline-button {
    margin-top: 10px;
    color: #ca987d;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
}

.cart-confirm-popup__decline-button:hover {
    text-decoration: underline;
    cursor: pointer;
}

.popup-modal {
    .cart-confirm-popup {
        background-color: white;
        color: black;
    }
}

.popup-modal__description b {
   font-weight: bold;
    font-size: 20px;
    color: #231f20;

    /*background-color: #b24636;*/
    -webkit-font-smoothing: antialiased;
}

.popup-modal__description p, .popup-modal__description ul {
    margin: 10px 0;
    font: 20px/1.18 'Helvetica', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    list-style: inside;
}

.popup-modal__description {
    .actions-wrapper {
        display: flex;
        flex-direction: column;
    }
    padding: 30px;
}
.confirm-popup-modal input {
    margin-right: 10px;
    transform: scale(1.5);
}

.confirm-popup-modal label {
    margin-bottom: 10px;
    display: flex;
}

.confirm-popup-modal label input {
    transform: scale(1.5);
}


.modal-privacy-policy .popup__close {
    position: fixed;
    top: 3.8% !important;
    right: 6% !important;
    left: auto !important;
}

.modal-privacy-policy .s-title {
   margin-top: 45px;
}

.modal-privacy-policy .cart-confirm-popup__title {
    margin-top: 10px;
}

.modal-privacy-policy .popup__close:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73.2 73.2'%3E%3Cpath fill='none' stroke='%23ca987d' stroke-miterlimit='10' stroke-width='5' d='M1.77 1.77l69.66 69.66M71.43 1.77L1.77 71.43'/%3E%3C/svg%3E");
}
