.content-page {
  max-width: 980px;
  border-bottom: 2px solid #ffefe6;
  margin: 0 auto 28px;
  padding-bottom: 40px;
}
.content-page blockquote {
  font-size: 35px;
  margin: 0 0 24px;
}
.content-page p {
  margin-top: 0;
  margin-bottom: 19px;
}

.content-page img {
  max-width: 100%;
}

.content-page p img {
  display: inline-block;
}

@media all and (max-width: 479px) {
  .content-page {
    padding-left: 15px;
    padding-right: 15px;
  }
  .content-page blockquote {
    font-size: 25px;
    margin: 0 0 24px;
  }
}
