.additional-goods {
  position: relative;
  max-width: 930px;
  margin: 0 auto 28px;
  padding-bottom: 2px;
}

.additional-goods:after {
  position: absolute;
  z-index: 1;
  content: '';
  bottom: 0;
  left: -25px;
  right: -25px;
  height: 2px;
  background-color: #ffefe6;
}

.additional-goods__list {
  list-style-type: none;
  padding: 0 3px 0 0;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.additional-goods__list-item {
  width: 124px;
  padding-left: 15px;
  padding-bottom: 40px;
  color: #ca987d;
  font-size: 15px;
  line-height: 1;
}

.additional-goods__list-item[data-item="incart"] {
  color: #231f20;
}

.additional-goods__list-item[data-item="incart"] .additional-goods-quantity__controls {
  width: 32px;
  height: 32px;
  background-color: #41463e;
  color: #ca987d;
  font-size: 21px;
  line-height: 1;
}

.additional-goods__list-item[data-item="incart"] .additional-goods-quantity__controls[data-button-type="plus"] {
  padding-top: 4px;
}

.additional-goods__list-item[data-item="incart"] .additional-goods-quantity__controls:hover {
  color: #ffefe6;
  background-color: #637c6c;
}

.additional-goods__picture {
  display: block;
  margin-bottom: 13px;
}

.additional-goods__picture img {
  position: relative;
  z-index: 3;
  display: block;
  vertical-align: top;
}

.additional-goods__picture:after {
  position: absolute;
  z-index: 1;
  content: '';
  left: -25px;
  right: -25px;
  height: 2px;
  background-color: #ffefe6;
}

.additional-goods__title {
  font-size: 1em;
  line-height: 1em;
  min-height: 2em;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 9px;
}

.warmer-goods__title {
  font-size: 1.5em;
  line-height: 1em;
  min-height: 2em;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 9px;
}

.additional-goods__size {
  margin-top: 0;
  margin-bottom: 13px;
  font-family: 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.additional-goods__price {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 14px;
  font-weight: 900;
  font-family: 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.additional-goods-quantity {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.additional-goods-quantity__controls {
  background-color: #ffefe6;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #ca987d;
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  text-align: center;
  vertical-align: top;
}

.additional-goods-quantity__controls:hover {
  color: #fff;
  background-color: #e4c3b1;
}

.additional-goods-quantity__controls[data-button-type="plus"] {
  padding-top: 5px;
}

.additional-goods-quantity__numbers {
  font-size: 20px;
  line-height: 1;
  color: #231f20;
  min-width: 33px;
  padding: 0 3px;
  text-align: center;
}

@media all and (max-width: 479px) {
  .additional-goods:after {
    right: 0;
  }

  .additional-goods__list {
    margin-left: 0;
  }

  .additional-goods__picture:after {
    right: 0;
  }

  .additional-goods__title {
    min-height: 3em;
  }
}

@supports (display: grid) {
  .additional-goods__list {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(124px, 1fr));
    grid-gap: 0;
  }
  .additional-goods__list-item {
    width: auto !important;
  }
}
