.infobox {
  color: #fff;
  font-size: 20px;
  background-color: #637c6c;
  padding: 19px 20px 20px;
  width: fit-content;
  margin-bottom: 54px;
}

.infobox a {
  color: #fff;
  text-decoration: underline;
}

.infobox-content p {
  margin: 0;
  line-height: 24px;
}

@media all and (max-width: 479px) {
  .infobox {
    font-size: 13px;
    padding: 10px 15px;
    margin-bottom: 20px;
  }
}
