.pickup-nav {
  font-size: 25px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pickup-nav__list-item {
  margin: 0 0 15px 0;
}

.pickup-nav__item {
  display: inline-block;
  color: #ffefe6;
  background-color: #607969;
  text-align: center;
  padding: 5px 15px;
  border: none;
  border-radius: 22px;
  cursor: pointer;
}
.pickup-nav__item:hover {
  color: #fff;
  text-decoration: none;
}
.pickup-nav__item--selected {
  background-color: #607969;
  color: #fff;
  text-decoration: none;
}
.pickup-nav-remove {
  font-size: 17px;
  line-height: 1.2;
  margin: 15px 0 0;
}
.pickup-nav-remove__btn {
  padding: 0;
  margin: 0;
  list-style-type: none;
  background: none;
  border: none;
  color: #ca987d;
}
.pickup-nav-remove__btn:hover {
  color: #41463e;
  text-decoration: none;
}

.pickup-nav__item[disabled] {
  color: #ca987d;
}

.pickup-nav__item[disabled]:hover {
  background-color: unset;
  color: #ca987d;
  text-decoration: none;
  cursor: unset;
}

.pickup-nav__item-disabled-comment {
  margin: auto;
  max-width: 600px;
  line-height: 18px;
}
