.main-slider {
  margin-bottom: 20px;
}

.main-goods {
  max-width: 930px;
  margin: 0 auto 17px;
}
.main-goods-types {
  position: relative;
  z-index: 4;
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 35px;
  line-height: 1;
  font-family: 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.main-goods-types__list-item {
  max-width: 134px;
  width: 40%;
  position: relative;
  right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-goods__list {
  list-style-type: none;
  padding: 0 3px 0 0;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.main-goods__list:after {
  position: absolute;
  z-index: 1;
  content: '';
  bottom: 10px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ffefe6;
}
.main-goods__list-item {
  position: relative;
  width: 33.33%;
  padding-left: 14px;
  margin-bottom: 10px;
  color: #ca987d;
  display: -webkit-box;
  display: flex;
}
.main-goods__list-item:after {
  position: absolute;
  z-index: 1;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ffefe6;
}
.main-goods__list-item[data-item="incart"] {
  color: #231f20;
}
.main-goods__list-item[data-item="incart"] .main-goods-quantity__controls {
  background-color: #41463e;
  color: #ca987d;
}
.main-goods__list-item[data-item="incart"] .main-goods-quantity__controls:hover {
  color: #ffefe6;
  background-color: #637c6c;
}

.main-goods__item {
  position: relative;
  z-index: 3;
  text-align: center;
  padding: 23px 0 27px;
  margin-bottom: -11px;
  overflow: hidden;
  width: 100%;
}
.main-goods__item a {
  color: currentColor;
}

.main-goods__title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 4;
  margin-top: 0;
  margin-bottom: 21px;
  font-size: 25px;
  line-height: 1.1em;
  min-height: 3.3em;
  padding: 0 15px;
}
.main-goods__title-mini {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 4;
  margin-top: 0;
  margin-bottom: 21px;
  font-size: 25px;
  line-height: 1.1em;
  min-height: 0.3em;
  padding: 0 15px;
}
.main-goods__price {
  font-weight: 900;
}
.main-goods__price-avoided {
  font-size: 18px;
  text-decoration: line-through;
}
.main-goods__picture {
  position: relative;
  z-index: 4;
  display: block;
  margin: 0 0 13px;
  padding: 0;
  text-align: center;
  cursor: pointer;
}
.main-goods__picture source,
.main-goods__picture img {
  vertical-align: top;
}
.main-goods__picture img {
  max-width: 100%;
  width: auto;
  height: auto;
}

.main-goods__portion {
  position: absolute;
  top: -8px;
  font-size: 15px;
  white-space: nowrap;
}

.main-goods__price-box {
  position: relative;
}

.main-goods__avoided-price {
  text-decoration: line-through;
  font-size: 15px;
}

.main-goods__size {
  opacity: 0.5;
  font-size: 11px;
  margin-left: 4px;
  margin-top: -7px;
}

.main-goods__chars {
  position: relative;
  z-index: 4;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
  padding: 0 15px;
}
.main-goods__chars p {
  margin-top: 0;
  margin-bottom: 0;
}

.main-goods__label {
  position: relative;
  z-index: 4;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
}
.main-goods__label[data-item-type="vegan"] {
  color: #637c6c;
}
.main-goods__label[data-item-type="new"] {
  color: #231f20;
}
.main-goods__label[data-item-type="hot"] {
  color: #b24636;
}

.main-goods-quantity {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 16px;
}
.main-goods-quantity__controls {
  position: relative;
  z-index: 4;
  background-color: #ffefe6;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #ca987d;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  padding: 0;
  margin: 0;
  text-align: center;
  vertical-align: top;
}
.popup-product .main-goods-quantity__controls {
  background-color: #41463e;
}

.main-goods-quantity__controls[data-button-type="plus"] {
  padding-top: 5px;
}
.main-goods-quantity__numbers {
  position: relative;
  z-index: 4;
  font: 25px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #231f20;
  min-width: 60px;
  padding: 0 10px;
  text-align: center;
}


@media all and (min-width: 980px) {
  .main-goods__item:hover {
    background-color: #ffefe6;
  }

  .main-goods__item:hover .main-goods-quantity:before {
    position: absolute;
    content: '';
    left: 50%;
    margin-left: -243px;
    width: 486px;
    height: 486px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 204.79 204.79'%3E%3Cg fill='%23ffffff'%3E%3Cpath d='M102.39 204.78a102.39 102.39 0 11102.39-102.39 102.5 102.5 0 01-102.39 102.39zm0-202.78a100.39 100.39 0 10100.39 100.39A100.5 100.5 0 00102.39 2z'/%3E%3Cpath d='M102.4 195.22a92.83 92.83 0 1192.84-92.83 92.94 92.94 0 01-92.84 92.83zm0-183.33a90.5 90.5 0 1090.5 90.5 90.6 90.6 0 00-90.5-90.5z'/%3E%3Cpath d='M102.42 185.66a83.27 83.27 0 1183.27-83.27 83.37 83.37 0 01-83.27 83.27zm0-163.88a80.61 80.61 0 1080.6 80.61 80.7 80.7 0 00-80.6-80.61z'/%3E%3Cpath d='M102.43 176.11a73.71 73.71 0 1173.71-73.71 73.79 73.79 0 01-73.71 73.71zm0-144.42a70.71 70.71 0 1070.71 70.71 70.79 70.79 0 00-70.71-70.72z'/%3E%3Cpath d='M102.44 166.54a64.15 64.15 0 1164.15-64.15 64.23 64.23 0 01-64.15 64.15zm0-125a60.82 60.82 0 1060.82 60.82 60.88 60.88 0 00-60.82-60.79z'/%3E%3Cpath d='M102.45 156.98a54.59 54.59 0 1154.59-54.59 54.65 54.65 0 01-54.59 54.59zm0-105.51a50.92 50.92 0 1050.93 50.92 51 51 0 00-50.93-50.92z'/%3E%3Cpath d='M102.46 147.42a45 45 0 1145-45 45.08 45.08 0 01-45 45zm0-86.06a41 41 0 1041 41 41.08 41.08 0 00-41-41z'/%3E%3Cpath d='M102.48 137.86a35.47 35.47 0 1135.47-35.47 35.51 35.51 0 01-35.47 35.47zm0-66.61a31.14 31.14 0 1031.13 31.14 31.18 31.18 0 00-31.13-31.14z'/%3E%3Cpath d='M102.49 128.3a25.91 25.91 0 1125.91-25.91 25.94 25.94 0 01-25.91 25.91zm0-47.15a21.24 21.24 0 1021.29 21.24 21.27 21.27 0 00-21.29-21.28z'/%3E%3Cpath d='M102.5 118.74a16.35 16.35 0 1116.35-16.35 16.38 16.38 0 01-16.35 16.35zm0-27.7a11.35 11.35 0 1011.35 11.35 11.36 11.36 0 00-11.35-11.35z'/%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .main-goods__item:hover .main-goods-quantity__controls {
    background-color: #41463e;
    color: #ca987d;
  }

  .main-goods__item:hover .main-goods-quantity__controls:hover {
    color: #ffefe6;
    background-color: #637c6c;
  }
}

@media all and (max-width: 979px) {
  .main-goods__title {
    font-size: 20px;
    min-height: 3.3em;
  }

  .main-goods__title-mini {
    font-size: 20px;
    min-height: 0.3em;
  }

  .main-goods-types {
    font-size: 25px;
  }
}
@media all and (max-width: 822px) {
  .main-goods-types {
    font-size: 20px;
  }

  .main-goods__avoided-price {
    font-size: 12px;
  }

  .main-goods__portion {
    font-size: 12px;
    margin-left: 2px;
    top: -5px;
  }

  .main-goods__size {
    margin-left: 2px;
  }

  .main-goods__title {
    font-size: 18px;
    line-height: 1.1em;
    min-height: 4.4em;
  }
  .main-goods__title-mini {
    font-size: 18px;
    line-height: 1.1em;
    min-height: 0.4em;
  }
}

@media all and (max-width: 734px) {
  .main-goods-types {
    font-size: 18px;
  }
}

@media all and (max-width: 579px) {
  .main-goods__title {
    font-size: 17px;
    line-height: 1.1em;
    min-height: 0.4em;
  }

  .main-goods__title-mini {
    font-size: 17px;
    line-height: 1.1em;
    min-height: 0.4em;
  }

  .main-goods-types {
    font-size: 16px;
  }

  .main-goods__list-item {
    padding-left: 8px;
  }

  .main-goods-quantity__numbers {
    font-size: 17px;
    min-width: 39px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .main-goods__chars {
    font-size: 13px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media all and (max-width: 479px) {
  .main-goods-types__list-item:first-child {
    padding-right: 6px;
  }

  .main-goods-types__list-item:nth-child(2) {
    padding-left: 6px;
  }

  .main-goods__list:after {
    bottom: 3px;
  }

  .main-goods__list-item {
    width: 50%;
    padding-bottom: 4px;
    padding-left: 3px;
    margin-bottom: 3px;
  }

  .main-goods__list-item:after {
    right: -3px;
    left: 0;
  }

  .main-goods__list-item[data-item="incart"] {
    color: #231f20;
  }

  .main-goods__list-item[data-item="incart"] .main-goods__item {
    background-color: #ffefe6;
  }

  .main-goods__list-item[data-item="incart"] .main-goods-quantity:before {
    position: absolute;
    z-index: -1;
    content: '';
    left: 50%;
    margin-left: -243px;
    width: 486px;
    height: 486px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 204.79 204.79'%3E%3Cg fill='%23ffffff'%3E%3Cpath d='M102.39 204.78a102.39 102.39 0 11102.39-102.39 102.5 102.5 0 01-102.39 102.39zm0-202.78a100.39 100.39 0 10100.39 100.39A100.5 100.5 0 00102.39 2z'/%3E%3Cpath d='M102.4 195.22a92.83 92.83 0 1192.84-92.83 92.94 92.94 0 01-92.84 92.83zm0-183.33a90.5 90.5 0 1090.5 90.5 90.6 90.6 0 00-90.5-90.5z'/%3E%3Cpath d='M102.42 185.66a83.27 83.27 0 1183.27-83.27 83.37 83.37 0 01-83.27 83.27zm0-163.88a80.61 80.61 0 1080.6 80.61 80.7 80.7 0 00-80.6-80.61z'/%3E%3Cpath d='M102.43 176.11a73.71 73.71 0 1173.71-73.71 73.79 73.79 0 01-73.71 73.71zm0-144.42a70.71 70.71 0 1070.71 70.71 70.79 70.79 0 00-70.71-70.72z'/%3E%3Cpath d='M102.44 166.54a64.15 64.15 0 1164.15-64.15 64.23 64.23 0 01-64.15 64.15zm0-125a60.82 60.82 0 1060.82 60.82 60.88 60.88 0 00-60.82-60.79z'/%3E%3Cpath d='M102.45 156.98a54.59 54.59 0 1154.59-54.59 54.65 54.65 0 01-54.59 54.59zm0-105.51a50.92 50.92 0 1050.93 50.92 51 51 0 00-50.93-50.92z'/%3E%3Cpath d='M102.46 147.42a45 45 0 1145-45 45.08 45.08 0 01-45 45zm0-86.06a41 41 0 1041 41 41.08 41.08 0 00-41-41z'/%3E%3Cpath d='M102.48 137.86a35.47 35.47 0 1135.47-35.47 35.51 35.51 0 01-35.47 35.47zm0-66.61a31.14 31.14 0 1031.13 31.14 31.18 31.18 0 00-31.13-31.14z'/%3E%3Cpath d='M102.49 128.3a25.91 25.91 0 1125.91-25.91 25.94 25.94 0 01-25.91 25.91zm0-47.15a21.24 21.24 0 1021.29 21.24 21.27 21.27 0 00-21.29-21.28z'/%3E%3Cpath d='M102.5 118.74a16.35 16.35 0 1116.35-16.35 16.38 16.38 0 01-16.35 16.35zm0-27.7a11.35 11.35 0 1011.35 11.35 11.36 11.36 0 00-11.35-11.35z'/%3E%3C/g%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .main-goods__item {
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  .main-goods__title {
    font-size: 15px;
    line-height: 1em;
    min-height: 3em;
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-goods__title-mini {
    font-size: 15px;
    line-height: 1em;
    min-height: 1em;
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-goods-quantity {
    margin-bottom: 9px;
  }

  .main-goods-types {
    font-size: 20px;
    margin-bottom: 13px;
    margin-left: 0;
  }

  .main-goods__avoided-price {
    font-size: 9px;
  }

  .main-goods__portion {
    font-size: 9px;
    margin-left: 0;
  }

  .main-goods__size {
    display: none;
  }

  .main-goods__chars {
    font-size: 11px;
  }
}

.left-bento-label {
    top: 0;
    left: 0;
    position: absolute;
    vertical-align: top;
    color: #fff;
    font: 900 21px/24px 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: #637c6c;
    /* border-radius: 40px; */
    padding: 2px 15px 2px 120px;
    min-width: 18px;
    text-align: center;
    /* margin-top: -3px; */
    /* margin-left: -30px; */
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.show-bento-button {
    margin-top: 10px;
    color: #ffefe6;
    text-decoration: underline;
    font-size: 17px;
}

.show-bento-button:hover {
    color: #ca987d;
    cursor: pointer;
}

.btn-show-all-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font: bold 30px Circe, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #ffefe6;
  color: #ca987d;
  cursor: pointer;
  margin: 10px 0;
}

.btn-title {
  font: bold 20px Circe, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}

.rollset-item {
  margin: 7px;
}

.triangle-close {
  border: 6px solid transparent;
  border-top: 7px solid #ca987d;
  margin-left: 5px;
  margin-top: 5px;
}

.triangle-open {
  border: 6px solid transparent;
  border-bottom: 7px solid #ca987d;
  margin-left: 5px;
  margin-bottom: 5px;
}

.triangle-close-all {
  border: 9px solid transparent;
  border-top: 11px solid #ca987d;
  margin-left: 10px;
  margin-top: 8px;
}

.triangle-open-all {
  border: 9px solid transparent;
  border-bottom: 11px solid #ca987d;
  margin-left: 10px;
  margin-bottom: 8px;
}

.collapse-description-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-more-detailed-product {
  margin-top: 15px!important;
  font: bold 22px Circe, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}



