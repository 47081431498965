.popup--product {
  background-color: #ffefe6;
}
.popup--product .circle__inner {
  fill: #fff;
}
.popup--product .popup__close:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73.2 73.2'%3E%3Cpath fill='none' stroke='%23ca987d' stroke-miterlimit='10' stroke-width='5' d='M1.77 1.77l69.66 69.66M71.43 1.77L1.77 71.43'/%3E%3C/svg%3E");
}

.popup-product {
  text-align: center;
  padding: 10px 0;
  color: #ca987d;
}
.popup-product__title {
  position: relative;
  z-index: 4;
  color: #231f20;
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 40px;
}
.popup-product-types {
  color: #231f20;
  position: relative;
  z-index: 4;
  list-style-type: none;
  padding: 0;
  margin: 0 0 17px -4px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 35px;
  line-height: 1;
  font-family: 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.popup-product-types__list-item {
  padding: 0 0 0 14px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
}
.popup-product-types__portion {
  font-size: 15px;
  margin-left: 5px;
  margin-top: -3px;
}
.popup-product-types__size {
  opacity: 0.5;
  font-size: 11px;
  margin-left: 4px;
  margin-top: -7px;
}
.popup-product__price {
  font-weight: 900;
}
.popup-product__picture {
  position: relative;
  z-index: 4;
  display: block;
  margin: 0 0 33px;
  padding: 0;
  text-align: center;
}
.popup-product__picture source,
.popup-product__picture img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.popup-product__chars {
  position: relative;
  z-index: 4;
  font-size: 15px;
  margin-bottom: 22px;
}
.popup-product__chars p {
  margin-top: 0;
  margin-bottom: 0;
}
.popup-product-ingredients {
  position: relative;
  z-index: 4;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  text-align: left;
  font-size: 15px;
}
.popup-product-quantity {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 16px;
}
.popup-product-quantity__controls {
  position: relative;
  z-index: 4;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  padding: 0;
  margin: 0;
  text-align: center;
  vertical-align: top;
  background-color: #41463e;
  color: #ca987d;
}
.popup-product-quantity__controls:hover {
  color: #ffefe6;
  background-color: #637c6c;
}
.popup-product-quantity__controls[data-button-type="plus"] {
  padding-top: 5px;
}
.popup-product-quantity__numbers {
  position: relative;
  z-index: 4;
  font: 25px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #231f20;
  min-width: 60px;
  padding: 0 10px;
  text-align: center;
}

@media all and (max-width: 579px) {
  .popup--product .popup__close {
    width: 44px;
    height: 44px;
    right: 10px;
    top: 10px;
  }

  .popup-product {
    padding: 0 15px;
  }
  .popup-product__title {
    margin-bottom: 20px;
  }
  .popup-product-quantity {
    position: relative;
    z-index: 1;
  }
  .popup-product-quantity .circle {
    left: 0;
    top: 0;
    overflow: visible;
  }
  .popup-product-quantity .circle__inner {
    left: 50%;
    right: auto;
    top: 50%;
    margin-right: 0;
    margin-left: -207px;
    margin-top: -207px;
  }
}
