.header-stop-informer {
    background-color: #F7E95A;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
}

.header-stop-informer__informer-icon {
    width: auto;
    height: 54px;
}

.header-stop-informer__informer-icon-container {
    padding-left: 20px;
    width: fit-content;
    height: 54px;
}

.header-stop-informer__informer-text {
    padding-left: 20px;
    display: flex;
    align-items: center;
}

@media all and (max-width: 822px) {
    .header-stop-informer {
        padding: 15px 0;
    }
}

@media all and (max-width: 579px) {
    .header-stop-informer {
        flex-direction: column;
        justify-content: center;
    }

    .header-stop-informer__informer-text {
        text-align: center;
    }

    .header-stop-informer__informer-icon-container {
        padding-bottom: 5px;
    }
}

.promo-cookie-banner-img {
    width: 100%;
}

.promo-cookie-banner-container {
    margin: 20px 20px 20px 0;
}
