.s-description {
  color: #999;
  font-size: 14px;
  line-height: 1;
  overflow: hidden;
}

@media all and (max-width: 479px) {
  .s-description {
    font-size: 12px;
  }
}
