.rollset-detail-item-wrapper {
  display: grid;
  grid-template-columns: 20% 80%;
  grid-gap: 13px;
}

.rollset-detail-products-wrapper {
  margin: 0 auto;
  width: 30%;
}

.rollset-detail-item-description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

@media all and (max-width: 599px) {
  .rollset-detail-products-wrapper {
    margin: 0 auto;
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .rollset-detail-products-wrapper {
    margin: 0 auto;
    width: 65%;
  }
}
@media (min-width: 901px) and (max-width: 1200px) {
  .rollset-detail-products-wrapper {
    margin: 0 auto;
    width: 50%;
  }
}

.rollset-detail-item-description-wrapper .popup-product-child-announce {
  text-align: start;
}
.rollset-detail-item-description-wrapper .popup-product-child-title {
  text-align: start;
}

.no-wrap {
  white-space: nowrap;
}