.body-section {
  max-width: 980px;
  margin: 0 auto;
}
.cart-cookies-section {
  background: #ffefe5;
}

.cart-cookies-section-info {
  font-family: 'sans-serif', Helvetica, Arial, Roboto, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 22px;
  font-weight: 600;
  color: #c79478;
  text-align: center;
  padding: 20px 0;
}

.cart-cookies-section-list {
  justify-content: center;
}

@media all and (max-width: 1150px) {
  .body-section {
    max-width: 85%;
  }
}

@media all and (max-width: 822px) {
  .body-section {
    max-width: 90%;
  }
  .cart-cookies-section-info {
    font-size: 17px;
  }
}

@media all and (max-width: 579px) {
  .body-section {
    max-width: 95%;
  }
  .cart-cookies-section-info {
    font-size: 15px;
  }
  .cart-cookies-section-list li {
    padding: 0;
  }
}
