.react-datepicker__time-container {
    width: 110px!important;
}

.react-datepicker__time-box {
    width: 110px!important;
}

.react-datepicker__navigation .react-datepicker__navigation--next {
    right: 105px!important;
}

.react-datepicker__header:not(.react-datepicker__header--time) {
    border-top-right-radius: 0;
}

.react-datepicker__header.react-datepicker__header--time {
    border-top-left-radius: 0;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 120px!important;
}


@media all and (max-width: 404px) {
    .react-datepicker__month-container {
        width: 100% !important;
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 10px !important;
    }

    .react-datepicker__time-container {
        width: 100% !important;
    }

    .react-datepicker__header.react-datepicker__header--time {
        border-radius: 0 !important;
    }

    .react-datepicker__time-container {
        width: 100% !important;
        border-left: none !important;
    }
}
