.form-finish-note-container {
    display: grid;
}

@media (min-width: 980px) {
    .form-finish-note-container {
        grid-template-columns: 50% 50%;
    }
}

@media (max-width: 979px) {
    .form-finish-note-container {
        grid-template-rows: 40% 60%;
    }
}