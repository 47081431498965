.secondary-goods {
  max-width: 930px;
  margin: 0 auto;
}
.secondary-goods__title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 4;
  margin-top: 0;
  margin-bottom: 19px;
  font-size: 25px;
  line-height: 1.1em;
  min-height: 2.2em;
}
.secondary-goods__item {
  text-align: center;
  padding-bottom: 14px;
  width: 100%;
}
.secondary-goods__price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 19px;
  font: 900 35px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.secondary-goods__discount {
  font-size: 15px;
  font-weight: 400;
  text-decoration: line-through;
}

.secondary-goods__title-box {
  position: relative;
}

.secondary-goods__amount {
  font-size: 15px;
  font-weight: 400;
  position: absolute;
  top: -4px;
  margin-left: 3px;
}

.secondary-goods__picture {
  position: relative;
  z-index: 4;
  display: block;
  margin: 0 0 20px;
  padding: 0;
  text-align: center;
}
.secondary-goods__picture source,
.secondary-goods__picture img {
  vertical-align: top;
}
.secondary-goods__picture img {
  max-width: 100%;
  width: auto;
  height: auto;
}
.secondary-goods__free {
  position: absolute;
  vertical-align: top;
  color: #fff;
  font: 900 11px/14px 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #637c6c;
  border-radius: 40px;
  padding: 3px 2px 1px;
  min-width: 18px;
  text-align: center;
  margin-top: -3px;
  margin-left: 7px;
}
.secondary-goods__list {
  list-style-type: none;
  padding: 0 3px 0 0;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.secondary-goods__list:after {
  position: absolute;
  z-index: 1;
  content: '';
  bottom: 30px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ffefe6;
}

.secondary-goods-quantity {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 16px;
}
.secondary-goods-quantity__controls {
  position: relative;
  z-index: 4;
  background-color: #ffefe6;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #ca987d;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  padding: 0;
  margin: 0;
  text-align: center;
  vertical-align: top;
}
.secondary-goods-quantity__controls:hover {
  color: #fff;
  background-color: #e4c3b1;
}
.secondary-goods-quantity__controls[data-button-type="plus"] {
  padding-top: 5px;
}
.secondary-goods-quantity__numbers {
  position: relative;
  z-index: 4;
  font: 25px/1 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #231f20;
  min-width: 60px;
  padding: 0 10px;
  text-align: center;
}

.secondary-goods__list-item {
  position: relative;
  width: 33.33%;
  padding-left: 15px;
  margin-bottom: 30px;
  color: #ca987d;
  display: -webkit-box;
  display: flex;
}

.secondary-goods__list[data-size="4"] .secondary-goods__list-item {
  width: 25%;
}

.secondary-goods__list-item:after {
  position: absolute;
  z-index: 1;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ffefe6;
}
.secondary-goods__list-item[data-item="incart"],
.secondary-goods__list-item__stick[data-item="incart"] {
  color: #231f20;
}

.secondary-goods__list-item[data-item="incart"] .secondary-goods-quantity__controls,
.secondary-goods__list-item__stick[data-item="incart"] .secondary-goods-quantity__controls {
  background-color: #41463e;
  color: #ca987d;
}
.secondary-goods__list-item[data-item="incart"] .secondary-goods-quantity__controls:hover,
.secondary-goods__list-item__stick[data-item="incart"] .secondary-goods-quantity__controls:hover {
  color: #ffefe6;
  background-color: #637c6c;
}

.secondary-goods__list-item__stick {
  position: relative;
  width: 100%;
  padding-left: 15px;
  margin-bottom: 30px;
  color: #ca987d;
  display: -webkit-box;
  display: flex;
}

.secondary-goods__list-item__stick .secondary-goods__picture img {
  height: 100%;
  width: auto;
}

@media all and (max-width: 979px) {
  .secondary-goods__title {
    font-size: 20px;
  }

  .secondary-goods__price {
    font-size: 25px;
  }

  .additional-goods__list-item {
    padding-bottom: 20px;
  }
}
@media all and (max-width: 822px) {
  .secondary-goods__list[data-size="4"] .secondary-goods__list-item {
    width: 50%;
  }
}

@media all and (max-width: 579px) {
  .secondary-goods__list-item {
    width: 50%;
  }

  .secondary-goods__title {
    font-size: 17px;
  }

  .secondary-goods__price {
    font-size: 17px;
  }

  .secondary-goods__discount,
  .secondary-goods__amount {
    font-size: 9px;
    margin-left: 2px;
  }

  .secondary-goods-quantity__numbers {
    font-size: 17px;
    min-width: 39px;
    padding-left: 4px;
    padding-right: 4px;
  }
}
@media all and (max-width: 479px) {
  .secondary-goods__list {
    margin-left: 0;
    margin-right: 3px;
  }

  .secondary-goods__list:after {
    bottom: 10px;
    right: 0;
  }

  .secondary-goods__list[data-size="4"] .secondary-goods__list-item {
    width: 50%;
  }

  .secondary-goods__list-item {
    width: 50%;
    margin: 0 0 10px 0;
    padding-bottom: 4px;
  }

  .secondary-goods__list-item:after {
    left: -3px;
    right: -3px;
  }

  .secondary-goods__title {
    font-size: 17px;
  }

  .secondary-goods__price {
    font-size: 17px;
  }

  .secondary-goods-quantity__numbers {
    font-size: 17px;
    min-width: 39px;
    padding-left: 4px;
    padding-right: 4px;
  }
}
