@media all and (max-width: 979px) {
    /*.header__outer {*/
    /*    height: 410px;*/
    /*}*/

    /*.header__inner {*/
    /*    height: 410px;*/
    /*    padding-top: 68px;*/
    /*}*/

    .header__inner .brand-name__logo {
        width: 490px;
        height: 98px;
    }

    .header__inner .logo {
        width: 490px;
    }

    .header__inner .brand-name__wtf {
        margin-top: 18px;
    }

    .header__inner .header-bar {
        top: 16px;
        right: 20px;
    }

    .header__inner .header-bar__list-item[data-type="address"] {
        font-size: 13px;
        padding-top: 8px;
    }

    .header__inner .header-benefits {
        padding-top: 45px;
        max-width: 490px;
        margin: 0 auto 53px;
    }

    .corp-nav {
        position: relative;
        top: 10px;
        margin-bottom: 18px;
    }

    .corp-nav__list {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
    }

    .corp-nav__list-item {
        margin: 0 5px;
    }

    .corp-nav__note {
        display: none;
    }

    .corp-nav__count {
        margin-top: -1px;
    }

    .header-bar__list-item[data-type="price"] {
        font-size: 28px;
    }

    .header-bar__list-item[data-type="cart"] {
        font-size: 25px;
        padding-top: 1px;
    }

    .header-bar__type {
        font-size: 11px;
        margin-left: 3px;
        margin-top: -5px;
    }

    /*.header__outer {*/
    /*    min-height: 70px;*/
    /*}*/

    .body-header-minimal .header-sticky-logo {
        -webkit-transform: translate(0, 47px);
        transform: translate(0, 47px);
    }

    .header-sticky-logo {
        left: 20px;
        margin-left: 0;
        opacity: 1;
    }

    .header-sticky-nav .navigation {
        font-size: 17px;
        padding-left: 11px;
        -webkit-transform: translate(0, 20px);
        transform: translate(0, 20px);
    }

    .header-sticky-nav .navigation__list {
        -webkit-box-pack: start;
        justify-content: flex-start;
    }

    .header-sticky-nav .header-bar__list-item[data-type="cart"] {
        font-size: 20px;
        padding-top: 4px;
    }

    .header-sticky-nav .header-benefits {
        display: none;
    }

    .header-bar__list-item {
        margin-left: 27px;
    }

    .header-sticky-nav .header-bar {
        top: 14px;
        right: 30px;
    }
}

@media all and (max-width: 679px) {
    .header-bar__item_address {
        width: 50%;
        position: relative;
        right: 10px;

        display: flex;
        justify-content: flex-end;
    }

    .header-sticky-nav .header-bar {
        left: 0;
        right: 0;
        -webkit-box-pack: center;
        justify-content: center;
    }

    .header-sticky-nav .header-bar__list-item[data-type="cart"] {
        position: absolute;
        right: 20px;
    }

    .header-sticky-nav .header-bar__list-item {
        margin-left: 0;
    }

    .header-sticky-nav .navigation__list-item {
        margin-bottom: 4px;
    }

    .navigation {
        font-size: 21px;
    }

    .hide-on-mobile {
        display: none !important;
    }

    .init-mobile-nav .header {
        bottom: 0;
    }

    .init-mobile-nav .header__outer {
        height: 100vh !important;
    }

    .init-mobile-nav .header__inner {
        display: none;
    }

    .init-mobile-nav .header-bar__item_address {
        display: none !important;
    }

    .init-mobile-nav .header .navigation {
        display: block !important;
        font-size: 25px;
        padding-top: 92px;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .init-mobile-nav .header .navigation__list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .init-mobile-nav .header .navigation a {
        color: #fff;
    }

    .init-mobile-nav .header .header-sticky-nav__close {
        position: absolute;
        z-index: 92;
        background: none;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        display: block;
        font: 0/0 transparent;
        width: 26px;
        height: 26px;
        background-image: url("data:image/svg+xml,%3Csvg data-name='DESIGN' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 73.2 73.2'%3E%3Cpath fill='none' stroke='%23fff0e7' stroke-miterlimit='10' stroke-width='5' d='M1.77 1.77l69.66 69.66M71.43 1.77L1.77 71.43'/%3E%3C/svg%3E");
        top: 16px;
        right: 44px;
    }

    .init-mobile-nav .header-sticky-nav .header-bar__list-item[data-type="price"] {
        display: none;
    }

    .init-mobile-nav .header-sticky-nav .header-bar {
        position: static;
        -webkit-box-pack: start;
        justify-content: flex-start;
        padding-left: 20px;
        padding-top: 8px;
    }

    .init-mobile-nav .header-sticky-nav .header-bar__list-item[data-type="cart"] {
        bottom: 0;
        font-size: 35px;
        position: static;
        text-align: left;
    }

    /*.header-bar {*/
    /*    top: 15px;*/
    /*}*/

    .header__inner .header-benefits__note {
        font-size: 12px;
    }
}

@media all and (max-width: 579px) {
    .header-bar__list-item[data-type="cart"] {
        font-size: 20px;
        padding-top: 4px;
    }

    .header-bar__list-item[data-type="address"] {
        position: absolute;
        font-size: 13px;
        line-height: 1;
        right: 0;
        top: 28px;
        padding-top: 0;
    }

    /*.header__inner {*/
    /*    height: 374px;*/
    /*    padding-top: 82px;*/
    /*}*/

    .header__inner .brand-name__logo {
        width: 294px;
        height: 59px;
    }

    .header__inner .logo {
        width: 294px;
        margin-bottom: 0;
    }

    .header__inner .brand-name__wtf {
        margin-top: 14px;
    }

    .header__inner .brand-name__wtf svg {
        width: 228px;
        height: 13px;
    }

    .header__inner .header-benefits__highlight {
        font-size: 30px;
    }

    .header__inner .header-benefits__list-item {
        margin-bottom: 30px;
    }

    .header__inner .header-benefits__list-item:first-child {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }

    .header__inner .corp-nav {
        margin-bottom: 14px;
    }

    .header__inner .corp-nav__list {
        font-size: 13px;
    }

    .header__inner .navigation {
        font-size: 15px;
        line-height: 1;
    }

    .header__inner .navigation__list-item {
        margin: 0 4px;
    }

    .body-header-minimal .header-sticky-logo {
        -webkit-transform: translate(0, 55px);
        transform: translate(0, 55px);
    }

    .header-sticky-logo .brand-name__logo {
        width: 134px;
        height: 27px;
    }

    /*.header__outer {*/
    /*    height: 374px;*/
    /*}*/
}

@media all and (max-width: 559px) {
    .header__inner .navigation {
        font-size: 14px;
        line-height: 1;
    }
}

@media all and (max-width: 479px) {
    .header__inner .navigation {
        font-size: 13px;
        line-height: 1;
    }
}

@media all and (max-width: 359px) {
    .header__inner .navigation {
        font-size: 12px;
        line-height: 1;
    }
}
