.user-toolbar {
  list-style-type: none;
  padding: 0;
  margin: 0 0 45px -12px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  color: #ffefe6;
}
.user-toolbar a {
  color: #ca987d;
}
.user-toolbar a:hover {
  color: #fff;
  text-decoration: none;
}
.user-toolbar__list-item {
  margin-left: 12px;
}
.user-toolbar__list-item[data-type="exit"] {
  margin-left: 24px;
}
