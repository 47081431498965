@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Mikado";
  src: url("../assets/fonts/Mikado-Bold.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Mikado";
  src: url("../assets/fonts/Mikado-Ultra.otf");
  font-weight: bold;
}


@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Medium.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Circe";
  src: url("../assets/fonts/CirceBold.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Circe";
  src: url("../assets/fonts/CirceExtraBold.otf");
  font-weight: bold;
}

@font-face {
  font-family: 'Circe-Bold';
  src: url("../assets/fonts/Circle-Bold.woff2") format('woff2');
  font-display: swap;
  font-weight: bold;
}

a:hover {
  text-decoration: none;
}

.p-l {
  padding: 12px!important;
}

.mb-s {
  margin-bottom: 8px;
}

.m-centered {
  margin-right: auto;
  margin-left: auto;
}

.mv-m {
  margin-top: 8px;
  margin-bottom: 8px;
}

.fs-m {
  font-size: 16px;
}

.fill-current {
  fill: currentColor;
}
