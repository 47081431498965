.width-ratio-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.width-ratio-container__content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.height-ratio-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.height-ratio-container__content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

