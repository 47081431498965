.warning {
  color: #231f20;
  font-size: 20px;
  background-color: #ffefe6;
  padding: 19px 20px 20px;
  width: fit-content;
  height: fit-content;
  margin-bottom: 10px;
  max-width: 980px;
}

.warning a {
  color: #231f20;
  text-decoration: underline;
}

.warning-content p {
  margin: 0;
  line-height: 24px;
}

@media all and (max-width: 479px) {
  .warning {
    font-size: 13px;
    padding: 10px 15px;
    margin-bottom: 20px;
  }
}
