[data-type="slider"] {
  width: 100%;
  display: block;
}
[data-type="slider"]:hover:before {
  display: none;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  background-color: white;
}

.BrainhubCarousel__dot--selected:before {
  background: #e1e665 !important;
}

.BrainhubCarousel__dot:hover:before {
  background: #cb987b !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  background: #f1d6c9;
  height: 13px;
  width: 13px;
}

.BrainhubCarousel__arrows:disabled {
  background-color: #ccc;
}

.BrainhubCarousel__arrows:hover:enabled {
  background-color: #b24636;
}

.BrainhubCarouselItem img {
  height: auto;
}

/*.arrow-left {*/
/*  position: absolute;*/
/*  width: 32px;*/
/*  height: 32px;*/
/*  background-color: #ffffff90;*/
/*  z-index: 1;*/
/*  left: 16px;*/
/*  border-radius: 50%;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*.arrow-right {*/
/*  position: absolute;*/
/*  width: 32px;*/
/*  height: 32px;*/
/*  background-color: #ffffff90;*/
/*  z-index: 1;*/
/*  right: 16px;*/
/*  border-radius: 50%;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

/*todo remove if needed*/
.main-goods .main-slider {
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.arrow {
  border: solid #637c6c;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}