.form-finish-note-container {
    display: flex;
    flex-direction: column;
}

.form-finish-note-container div {
    margin-top: 10px;
}

.form-finish-button__note span {
    color: #637c6c;
}

.form-finish-button__note span:hover {
    cursor: pointer;
}

.form-privacy-policy-wrapper input {
    transform: scale(1.5);

}

.form-privacy-policy-wrapper label {
    display: flex;
    box-shadow: 0 0 1px 7px #607969;
    border-radius: 2px;
    float: left;
}



.form-privacy-policy-container p {
    font-size: 13px;
}

.form-privacy-policy-container {
    display: flex;
    align-items: baseline;
}

.form-privacy-policy-container div {
    margin-top: 15px;
}

.form-privacy-policy-wrapper {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 5% 95%;
    grid-gap: 10px;
}

.cart-rules {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 5% 95%;
}

.form-privacy-policy-wrapper p {
    font-size: 13px;
    grid-column: 2;
    text-align: start;
}
.form-privacy-policy-wrapper a {
    color: #637c6c;
}

.form-privacy-policy-warning {
    box-shadow: 0 0 1px 7px #F7E95A !important;
}
