[data-type="slider"] {
  width: 100%;
  display: block;
}
[data-type="slider"]:hover:before {
  display: none;
}

.suggested-product-slider-title-wrapper ul {
  margin-left: 20px;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  background-color: white;
}

.BrainhubCarousel__dot--selected:before {
  background: #e1e665 !important;
}

.BrainhubCarousel__dot:hover:before {
  background: #cb987b !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  background: #f1d6c9;
  height: 13px;
  width: 13px;
}

.BrainhubCarousel__arrows:disabled {
  background-color: #ccc;
}

.BrainhubCarousel__arrows:hover:enabled {
  background-color: #b24636;
}

.BrainhubCarouselItem img {
  height: auto;
}

/*todo remove if needed*/
.main-goods .main-slider {
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.arrow-wrapper {
  background: #6a8875;
  border-radius: 50px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-wrapper:hover {
  cursor: pointer;
  background: #41463e;
}

.arrow-left {
  margin-right: 15px;
  border: 10px solid transparent;
  border-right: 18px solid white;
}

.arrow-right {
  margin-left: 15px;
  border: 10px solid transparent;
  border-left: 18px solid white;
}

.suggested-product-slider-title-wrapper {
  display: flex;
  align-items: center;
  color: #ca987d;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  text-transform: lowercase;
  margin-bottom: 13px;
}

@media all and (max-width: 479px) {
  .s-text {
    font-size: 20px;
  }
}

.suggested-product-list__item .secondary-goods__title {
  align-items: flex-start;
}
