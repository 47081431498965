.s-title {
  color: #ca987d;
  font-size: 30px;
  line-height: 1;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 19px;
  text-transform: lowercase;
}

.s-title__count {
  display: inline-block;
  vertical-align: top;
  color: #fff;
  font: 900 11px/14px 'Roboto', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: #637c6c;
  border-radius: 40px;
  padding: 3px 2px 1px;
  min-width: 18px;
  text-align: center;
  margin-top: -3px;
  margin-left: 3px;
}

@media all and (max-width: 479px) {
  .s-title {
    margin-left: 15px;
    margin-bottom: 13px;
    font-size: 20px;
  }

  .s-title__count {
    margin-top: 0;
  }
}

.section-anchor {
  position: relative;
  top: -100px;
}
